import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import CustomEasy from "../Components/PageComponents/CustomEasy";
import FeaturedStyles from "../Components/DesignStudioComponents/FeaturedStyles";
import FeaturedStylesMobile from "../Components/DesignStudioComponents/FeaturedStylesMobile";
import "./styles/designstudio.css";

const Spark = () => {
  let opts = {
    width: "100%",
    height: "625"
  };
  return (
    <div className="design-studio-wrapper">
      <div className="design-studio-header">
        <div className="design-studio-header-content">
          <h2>Signature Spark</h2>
          <h5>An interactive design tool to create your next floor.</h5>
        </div>
      </div>
      <div className="spark-marketing-block">
        <div className="smb-graphic">
        <img src="/wp-content/uploads/2020/06/spark-info-graphic-latest.jpg" alt="Spark Design Studio" height="300px" />
        </div>
        <div className="smb-text">
          <p className="bold-text">Ignite Your Creativity <span>with Signature Spark</span></p>
          <p>Recolor. Create. Visualize. Inspire.</p>
          <p>An easy to use custom design and inspiration website where you can recolor custom carpet patterns, create tackboards, manage project assets, find inspiration, visualize in roomscenes, and much more.</p>
          <div className="design-button">
          <a
            href="https://spark.signatureflooring.com/"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              Explore Signature Spark
            </button>
          </a>
        </div>
        </div>
        {/*<hr className="smb-hr" />*/}
      </div>
      {/*<div className="design-studio-above-fold">
        <h3>Visualize &amp; Recolor with Signature Spark</h3>
        Fueled by a passion for it's craft and a complete dedication to its
        clients, Signature is driven to achieve unique flooring solutions that
        expand each client's vision.
        <div className="design-button">
          <a
            href="https://spark.signatureflooring.com/"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">
              Explore Signature Spark
            </button>
          </a>
        </div>
  </div> */}
      <div className="spark-video" style={{ backgroundColor: "white" }}>
        <h3>Learn About Signature Spark</h3>
        <YouTube videoId="pNWP55CqOzc" opts={opts} />
      </div>
      <section className="ds-featuredstyles-section">
        <FeaturedStyles />
      </section>
      <div className="design-studio-how-does-it-work">
        <h3>How Does It Work?</h3>
        <div className="how-does-it-work-grid-wrapper">
          <div className="hdiw-col" style={{ textAlign: "center" }}>
            <img
              src="/wp-content/uploads/2020/04/spark-left-column-1.png"
              alt="column 1"
            />
            <h4>Choose Your Style</h4>
            <p style={{ textAlign: "center" }}>Select your floor.</p>
          </div>
          <div className="hdiw-col" style={{ textAlign: "center" }}>
            <img
              src="/wp-content/uploads/2020/04/spark-middle-column-1.png"
              alt="column 2"
            />
            <h4>Recolor</h4>
            <p style={{ textAlign: "center" }}>Choose your color.</p>
          </div>
          <div className="hdiw-col" style={{ textAlign: "center" }}>
            <img
              src="/wp-content/uploads/2020/04/spark-right-column-1.png"
              alt="column 3"
            />
            <h4>Visualize</h4>
            <p style={{ textAlign: "center" }}>
              View as a swatch or in a room scene.
            </p>
          </div>
        </div>
        <div className="design-button">
          <a
            href="https://spark.signatureflooring.com/"
            target="_new"
            rel="noopener noreferrer"
          >
            <button className="design-row-button-large">Get Started</button>
          </a>
        </div>
      </div>
      <CustomEasy />

      <section className="ds-featuredstyles-section-mobile">
        <FeaturedStylesMobile />
      </section>
    </div>
  );
};

export default Spark;
