import React, { useState } from 'react';


const InstallCard = (props) => {
  // If the title is "Hospice Family Room", change it to a new value.
  const title =
    props.data.title.rendered === "Hospice Family Room"
      ? "W.G. Hefner Department of Veterans Affairs"
      : props.data.title.rendered;

  return (
    <div className="installation">
      <div className="card" key={props.data.id}>
        <a href={`/installation-details/${props.data.slug}`}>
          <img
            src={props.data.acf.installation_main_image.url}
            className="card-img-top"
            alt={title}
          />
        </a>
        <div className="card-body">
          <a href="##">
            <p className="card-title">{title}</p>
          </a>
        </div>
      </div>
    </div>
  );
};



export default InstallCard;