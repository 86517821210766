import React, { Component } from 'react'
import axios from 'axios'
import SegmentBlocks from '../Components/PageComponents/SegmentBlocks'
import ReactPlayer from 'react-player'

import './styles/aboutpage.css'

class AboutPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      aboutUsTitle: '',
      aboutUsContent: '',
      secondContentBlock: '',
      pageVideo: ''
    }
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/pages?slug=aboutus')
      .then(res => {
        this.setState({
          aboutUsTitle: res.data[0].title.rendered,
          aboutUsContent: res.data[0].content.rendered,
          secondContentBlock: res.data[0].acf.second_content_block,
          pageVideo: res.data[0].acf.page_video
        }, () => console.log('pageContent', this.state.aboutUsContent))
      })
      .catch(errors => console.log(errors))
  }

  render() {
    const { aboutUsContent, aboutUsTitle, pageVideo } = this.state

    const buttonStyle = {
      padding: '10px 20px',
      backgroundColor: 'rgba(0,66,106,0.69)',
      border: 'none',
      borderRadius: '5px',
      color: '#FFFFFF',
      cursor: 'pointer',
      fontSize: '16px',
      marginRight: '10px'
    };
  
    const pdfButtonStyle = {
      padding: '10px 20px',
      backgroundColor: 'rgba(100,52,96,0.69)',
      border: 'none',
      borderRadius: '5px',
      color: '#FFFFFF',
      cursor: 'pointer',
      fontSize: '16px'
    };
    return (
      <div className="aboutus-studio-wrapper">
        <div className="aboutus-studio-header"> 
          <div className="aboutus-studio-header-content">
              <h2>About Us</h2>
          </div>
        </div>
        <div className="segemnt-above-fold">
          <h3>{ aboutUsTitle }</h3>
          <div dangerouslySetInnerHTML={{__html: aboutUsContent}} />
          <div style={{ marginTop: '30px'}}>
          <a href={pageVideo} target="_blank" rel="noopener noreferrer">
          <button className="sample-button" style={{ width: '300px' }}>History of Signature Flooring Video</button>
        </a>&nbsp;&nbsp;
        <a href="/wp-content/uploads/2025/02/Signature-Floors-for-Signature-Spaces.pdf" target="_blank" rel="noopener noreferrer">
        <button className="sample-button" style={{ width: '200px'}}>About Signature Flooring</button>
        </a>
          </div>
        </div>
        {/* <div className="about-us-video-block">
          <div className="about-history-video-wrapper">
            <div className="button-container" style={{ marginBottom: '20px', padding: '10px' }}>
        
            </div>
          </div>
        </div> */}
        
        <SegmentBlocks />
        {/*<div className="aboutus-history-content" dangerouslySetInnerHTML={{__html: secondContentBlock}} />*/}
        <div className="aboutus-history-content">
        <h3>Signature Provides An Experience As Inspiring And Authentic As Its Flooring</h3>
          <p>Signature is a vertically integrated manufacturer offering custom carpet, broadloom carpet, carpet tile, <br/>rugs and LVT. Through creative styling, world-class custom design capabilities, a spirit of innovation and the highest <br/>quality of service, Signature makes signature spaces a reality.</p>
            <div className="checkmarkWrapper">
              <div className="about-check-left">
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> We Make Custom Easy</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Experienced & Creative Custom Design</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Design Studio</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Carpet & Hard Surface Products</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Running Line Portfolio</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Program Management</p>
              </div>
              <div className="about-check-right">
              <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Planning & Estimating</p>
              <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Vertically Integrated Manufacturing</p>
              <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Colorpoint & Infinity Tufting Technology</p>
              <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Excellent Performance & Quality</p>
              <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Stain & Soil Technology</p>
              <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> BioCel Sustainable Backing Systems</p>
              </div>
            </div>  
        </div>
        <div className="aboutus-hear-from-you">
          <h2>We would love to hear from you.</h2>
          <div className="aboutus-contact-ways-wrapper">
              <div className="contact-ways1">
                <span className="contact-ways-header"><p>Phone & Email</p></span>
                <span className="contact-ways">
                  <p>(800) 809-7086</p>
                  <p>(706) 270-5799</p>
                  <p>(706) 270-8779 fax</p>
                </span>
                <span className="contact-email">info@signatureflooring.com</span>
              </div>

              <div className="contact-ways2">
                <span className="contact-ways-header"><p>Office</p></span>
                <span className="contact-ways">
                  <p>2222 South Hamilton Street</p>
                  <p>Dalton, Georgia 30721</p>
                </span>
              </div>

              <div className="contact-ways3">
                <span className="contact-ways-header"><p>Media Contact</p></span>
                <span className="contact-ways">
                  <p>Angie Law</p>
                  <p>(706) 270-5799</p>
                </span>
				<span className="contact-email">alaw@signatureflooring.com</span>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutPage
