import React, {useState, useEffect } from 'react'
import Masonry from "react-masonry-css";
import { Helmet } from 'react-helmet'
import ModalImage from "react-modal-image";
import './styles/installationpage.css'


const InstallationPage = (props) => {
  const pageSlug = props.match.params.install;
  const [installData, setInstallData] = useState({});

  const masonryOptions = {
    transitionDuration: 0
  };

  const fetchInstallData = async() => {
      const installDataApiCall = await fetch('/wp-json/wp/v2/installations/?slug='+pageSlug);
      const idContent = await installDataApiCall.json();
      setInstallData(idContent)
  }

  useEffect(() => {
    fetchInstallData();
  }, [])

  return (
    <div>
      { installData.length > 0 &&
      <>
      <Helmet>
        <title>{installData[0].title.rendered} Project - Signature Flooring</title>
        <meta name="description" content={`${installData[0].title.rendered} installation project`} />
      </Helmet>
      
        <div className="custom-collections-wrapper-desktop" style={{ background: 'white'}}>
            <div className="custom-collections-container" style={{width: '100%'}}>
              <div className="custom-collections-header">
                <div className="col-md-12">
                  <h3 style={{fontFamily: 'Adelle Sans Lt'}}>Project: {installData[0].title.rendered}</h3>
                  <div style={{textAlign: 'left'}} className="pl-5">
                    <span style={{fontWeight: 'bold'}}>Firm:</span> {installData[0].acf.design_firm}<br/>
                    { installData[0].acf.photo_credit &&
                      <>
                      <span style={{fontWeight: 'bold'}}>Photo Credit:</span> {installData[0].acf.photo_credit}<br/>
                      </>
                    }
                    <span style={{fontWeight: 'bold'}}>Location:</span> {installData[0].acf.installation_location}<br/>
                    <span style={{fontWeight: 'bold'}}>Product:</span> <span className="install-product" dangerouslySetInnerHTML={{__html: installData[0].acf.product_type}} /><br/>
                    <p className="installation-actionbar-left">
                      <a href="/installations">Back to Installation Projects</a>
                    </p>  
                  </div>
                </div>
                
              </div>
              <div className="branded-program-body" style={{background: 'white', minHeight: '800px'}}>
              <div className="custom-collection-body" width="100%">
              <Masonry
                breakpointCols={3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {installData[0].acf.installation_images.map((image) => (
                  <ModalImage
                    small={image.roomscene.url}
                    large={image.roomscene.sizes.large}
                    alt={image.roomscene.title}
                    key={image.roomscene.id}
                  />
                ))}
              </Masonry>
              </div>
              </div>
              
            </div>
          </div>

          <div className="custom-collection-wrapper-mobile" style={{background: 'white'}}>
          <div className="custom-collection-container-mobile">
            <div className="custom-collection-header-mobile" style={{textAlign: 'left', paddingTop: '75px'}}>
              
              <p><span style={{fontWeight: 'bold'}}>Project:</span> {installData[0].title.rendered}</p>
              <div className="install-info-container">
              <div>
              <span style={{fontWeight: 'bold'}}>Firm:</span> {installData[0].acf.design_firm}<br/>
              { installData[0].acf.photo_credit &&
                <>
                <span style={{fontWeight: 'bold'}}>Photo Credit:</span> {installData[0].acf.photo_credit}<br/>
                </>
              }
              <span style={{fontWeight: 'bold'}}>Location:</span> {installData[0].acf.installation_location}<br/>
              <p className="custom-collection-actionbar-left">
                <a href="/installations">Back to Installation Projects</a>
              </p>
              </div>
              </div>
            </div>
            <div className="custom-collection-body">
            <Masonry
                breakpointCols={2}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {installData[0].acf.installation_images.map((image) => (
                  <ModalImage
                    small={image.roomscene.url}
                    large={image.roomscene.sizes.large}
                    alt={image.roomscene.title}
                    key={image.roomscene.id}
                  />
                ))}
              </Masonry>
            </div>
          </div>
        </div>
          </>
        }
    </div>
  )
}

export default InstallationPage
