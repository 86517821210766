import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import CustomEasy from "../Components/PageComponents/CustomEasy";
import LaminaCollectionFeaturedStyles from "../Components/HospitalityComponents/LaminaCollectionFeaturedStyles";
import LaminaCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/LaminaCollectionFeaturedStylesMobile";
import BoldaciousCollectionFeaturedStyles from "../Components/HospitalityComponents/BoldaciousCollectionFeaturedStyles";
import BoldaciousCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/BoldaciousCollectionFeaturedStylesMobile";
import LivingSketchesCollectionFeaturedStyles from "../Components/HospitalityComponents/LivingSketchesCollectionFeaturedStyles";
import LivingSketchesCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/LivingSketchesCollectionFeaturedStylesMobile";
import EmergeCollectionFeaturedStyles from "../Components/HospitalityComponents/EmergeCollectionFeaturedStyles";
import EmergeCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/EmergeCollectionFeaturedStylesMobile";
import OutboundCollectionFeaturedStyles from "../Components/HospitalityComponents/OutboundCollectionFeaturedStyles";
import OutboundCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/OutboundCollectionFeaturedStylesMobile";
import RendezvousOasisCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousOasisCollectionFeaturedStyles";
import "./styles/segmentshospitality.css";
import RendezvousOasisCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousOasisCollectionFeaturedStylesMobile";
import RendezvousLandscapesCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousLandscapesCollectionFeaturedStyles";
import RendezvousLandscapesCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousLandscapesCollectionFeaturedStylesMobile";
import RendezvousMonarchCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousMonarchCollectionFeaturedStyles";
import RendezvousMonarchCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousMonarchCollectionFeaturedStylesMobile";
import RendezvousVistaCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousVistaCollectionFeaturedStyles";
import RendezvousVistaCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousVistaCollectionFeaturedStylesMobile";



const SegmentsHospitality = () => {
	
  {/*
  let opts = {
    width: "100%",
    height: "310"
  };
  */}
  
  let opts = {
    width: "85%",
    height: "310"
  };
  
  
  return (
      <div className="hospitality-wrapper">

        <div className="hospitality-header">
            <div className="hospitality-header-content"></div>
        </div>

        <div className="spark-video" style={{backgroundColor: "white", marginBottom: "-15px"}}>
            <p><br></br></p>
            <h3>An Exceptional Experience</h3>
            <p style={{margin: "0% 20% 0% 20%", textAlign: "center"}}>From extraordinary custom carpets to
                  sophisticated running-line soft surface and hard surface flooring, Signature Flooring offers an
                  unparalleled level of design, quality and service to help you create an exceptional experience for
                  guests. Discover our exciting range of flooring and custom design capabilities, and start bringing
                  your Signature space to life.</p>
            <p><br></br></p>
        </div>

        <div className="spark-video" style={{backgroundColor: "white"}}>
            <h3>Explore Custom Collections</h3>
        </div>

        {/* Begin Rendezvous Collection Section */}

        <div className="hospitality-rendezvous-collection-hero">
            <a href="/custom_collections/rendezvous-oasis-collection/">
                <div className="hospitality-rendezvous-collection-hero-content" style={{paddingBottom: "20px!important"}}></div>
            </a>
        </div>

        <div className="hospitality-generic-video-hero" style={{ marginTop: '10px'}}>
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Oasis-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

            <div className="hospitality-marketing-block">
                <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>


                <div className="smb-graphic2">
                    <a href="/custom_collections/rendezvous-oasis-collection/">
                        <img src="/wp-content/uploads/2024/12/Rendezvous-Oasis-RS_SG009_500x500.jpg" alt="Journey" width="350px" height="310px"/>
                    </a>
                </div>

                <div className="smb-text2">
                    <p className="bold-text">Rendezvous Oasis</p>
                    <p>Rendezvous Oasis Collection captures the lush, vibrant essence of an outdoor sanctuary. Its rich, earthy tones and striking designs create a deep connection to nature.</p>
                    <p>The Rendezvous Oasis Collection introduces a refreshing aesthetic that enriches and rejuvenates guestrooms, public spaces and corridors, inviting a serene ambiance into every space. Rendezvous Oasis designed in collaboration with Stacy Garcia Design Studio.</p>
                    <div className="buttons-wrapper">
                        <div className="design-button">
                            <a href="/wp-content/uploads/2024/10/Signature-Flooring_Oasis_Web.pdf" target="_new" rel="noopener noreferrer">
                                <button className="design-row-button-large">
                                    Download Brochure
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ds-featuredstyles-section">
                <RendezvousOasisCollectionFeaturedStyles />
            </section>

            <section className="ds-featuredstyles-section-mobile">
                <RendezvousLandscapesCollectionFeaturedStylesMobile/>
            </section>


            {/** Rendezvous Landscapes */}
            <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Landscapes-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
          <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>
              <div className="smb-graphic2">
                  <a href="productinfo/rendezvous-landscapes-atmosphere/" target="_new">
                      <img src="/wp-content/uploads/2025/03/StacyGarcia_SG005-Ridge_1507-Stone_RS_500x500.jpg" alt="Rendezvous Landscapes Collection"
                           height="310px" width="350"/>
                  </a>
              </div>
              <div className="smb-text2">
                  <p className="bold-text">Rendezvous Landscapes Collection</p>
                  <p>Rendezvous Landscapes combines luxurious elegance with soothing organic forms and natural textures, crafting a calm and inviting atmosphere for guestroom interiors.</p>
                  <p>Striking the perfect balance between beauty and serenity, it elevates the space with a refined tranquility, enhancing the overall guest experience. Rendezvous Landscapes designed in collaboration with Stacy Garcia Design Studio.</p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2025/03/Landscapes-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <RendezvousLandscapesCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <LaminaCollectionFeaturedStylesMobile/>
          </section>
          
          {/** End Rendezvous Landscapes */}

        {/** Rendezvous Monarch */}
        <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Monarch-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
          <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>
              <div className="smb-graphic2">
                  <a href="productinfo/rendezvous-monarch-flight/" target="_new">
                      <img src="/wp-content/uploads/2025/03/SG023T-Flow_1803-Canyon_RS_500x500.jpg" alt="Rendezvous Monarch Collection"
                           height="310px" width="350px"/>
                  </a>
              </div>
              <div className="smb-text2">
                  <p className="bold-text">Rendezvous Monarch Collection</p>
                  <p>Inspired by the delicate symmetry and vibrant colors of butterfly wings, Rendezvous Monarch captures the essence of elegance and fluidity, making it a stunning addition to any design.</p>
                  <p>This collection offers a harmonious blend of intricate patterns and rich textures, creating a visual tapestry that brings the enchanting allure of the natural world into the heart of hospitality environments. Rendezvous Monarch designed in collaboration with Stacy Garcia Studio.
                  </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2025/03/Monarch-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <RendezvousMonarchCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <RendezvousMonarchCollectionFeaturedStylesMobile/>
          </section>
          
          {/** End Rendezvous Monarch */}


          {/** Rendezvous Vista */}
        <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Vista-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
                <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>
              <div className="smb-graphic2">
                  <a href="productinfo/rendezvous-vista-spc/" target="_new">
                      <img src="/wp-content/uploads/2025/03/SIG558-SPC6130_RS_500x500-1.jpg" alt="Rendezvous Vista Collection"
                            width="350px" height="310px"/>
                  </a>
              </div>
              <div className="smb-text2">
                  <p className="bold-text">Rendezvous Vista Collection</p>
                  <p>Rendezvous Vista draws its design inspiration from the concept of expansive views and serene, unspoiled landscapes, bringing the essence of nature indoors.</p>
                  <p>This collection is a tribute to creating interiors that reflect the grandeur of open vistas, blending organic beauty with refined, modern elegance. Rendezvous Vista designed in collaboration with Stacy Garcia Studio.</p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2025/03/Vista-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <RendezvousVistaCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <RendezvousVistaCollectionFeaturedStylesMobile/>
          </section>
          
          {/** End Rendezvous Vista */}


          {/* End Rendezvous Collection Section */}

          {/* Begin Lamina Collection Section */}

          <div className="hospitality-lamina-collection-hero">
              <div className="hospitality-lamina-collection-hero-content">
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-graphic">
                  <a href="custom_collections/lamina-collection/" target="_new">
                      <img src="/wp-content/uploads/2024/10/Lamina_JR156_500x500.jpg" alt="Lamina Collection"
                           height="300px"/>
                  </a>
              </div>
              <div className="smb-text" style={{paddingTop: "24px"}}>
                  <p className="bold-text">Lamina Collection</p>
                  <p>Elevate hospitality spaces with Lamina, where the intricate layering of colors and patterns,
                      including those inspired by Ginkgo leaves, creates abstract art that embodies beauty, evoking
                      warmth and enhancing mood. </p>
                  <p>Experience the Lamina Collection for luxury flooring, transforming guestrooms, corridors, and
                      public spaces into stunning environments that captivate with their timeless allure. </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2024/04/SIG-Lamina-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <LaminaCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <LaminaCollectionFeaturedStylesMobile/>
          </section>

          {/* End Lamina Collection Section */}

          {/* Begin BOLDacious Collection Section */}

          <div className="hospitality-boldacious-collection-hero">
              <div className="hospitality-boldacious-collection-hero-content">
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-graphic">
                  <a href="custom_collections/boldacious-collection/" target="_new">
                      <img src="/wp-content/uploads/2024/10/Boldacious_HC143_500x500.jpg" alt="Boldacious Collection"
                           height="300px"/>
                  </a>
              </div>
              <div className="smb-text" style={{paddingTop: "24px"}}>
                  <p className="bold-text">BOLDacious Collection</p>
                  <p>BOLDacious is an expression of “more is truly more”. Beautiful in all its colors, patterns,
                      textures and layers of design favorites evoking joy and playfulness. From strikingly bright colors
                      to moody blues, Boldacious blends an eclectic mix of patterns with layers of texture into an
                      expressive collection that is arguably explosive, magical and desirable. Amaze guests and create
                      impressive hospitality flooring spaces with Boldacious. </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2023/11/SIG435-Boldacious-brochure_final.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <BoldaciousCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <BoldaciousCollectionFeaturedStylesMobile/>
          </section>

          {/* End BOLDacious Collection Section */}

          {/* Begin Living Sketches Collection Section */}

          <div className="hospitality-living-sketches-collection-hero">
              <div className="hospitality-living-sketches-collection-hero-content">
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-graphic">
                  <a href="custom_collections/living-sketches-collection/" target="_new">
                      <img src="/wp-content/uploads/2024/10/LivingSketches_HC137_500x500.jpg"
                           alt="Living Sketches Collection" height="300px"/>
                  </a>
              </div>
              <div className="smb-text" style={{paddingTop: "24px"}}>
                  <p className="bold-text">Living Sketches Collection</p>
                  <p>The Living Sketches Collection is inspired by the creative process of bringing beautiful art to
                      life. A simple, hand-drawn sketch can inspire a refined and expressive work of art. These designs
                      awaken your creativity with a layered combination of crisp lines, soft curves and intentional
                      brushstrokes. From bold geometrics to softer organic patterns, the Living Sketches collection
                      creates an inviting, yet dramatic effect in hospitality spaces. </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2022/11/Living-Sketches-Collection-Brochure-Final-10_2022.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <LivingSketchesCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <LivingSketchesCollectionFeaturedStylesMobile/>
          </section>

          {/* End Living Sketches Collection Section */}

          {/* Begin Emerge Collection Section */}

          <div className="hospitality-emerge-collection-hero">
              <div className="hospitality-emerge-collection-hero-content">
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-graphic">
                  <a href="custom_collections/emerge-collection/" target="_new">
                      <img src="/wp-content/uploads/2024/10/Emerge_HC124_500x500.jpg" alt="Emerge Collection"
                           height="300px"/>
                  </a>
              </div>
              <div className="smb-text" style={{paddingTop: "24px"}}>
                  <p className="bold-text">Emerge Collection</p>
                  <p>From beautiful details of butterfly wings to the vast flora upon which they alight, let your
                      imagination fly with the Emerge Collection creating scenic pathways throughout hospitality
                      spaces. </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2021/10/Emerge_Collection_Brochure_Final-compressed.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <EmergeCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <EmergeCollectionFeaturedStylesMobile/>
          </section>

          {/* End Emerge Collection Section */}

          {/* Begin Outbound Collection Section */}

          <div className="hospitality-outbound-collection-hero">
              <div className="hospitality-outbound-collection-hero-content">
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-graphic">
                  <a href="custom_collections/outbound-collection/" target="_new">
                      <img src="/wp-content/uploads/2024/10/Outbound_HC054_500x500.jpg" alt="Outbound Collection"
                           height="300px"/>
                  </a>
              </div>
              <div className="smb-text" style={{paddingTop: "24px"}}>
                  <p className="bold-text">Outbound Collection</p>
                  <p>Discover the OUTBOUND Collection, where the dimensional shapes of everyday storage
                      containers—crafted from ship vessels, plastic, glass, wood, and metal—are transformed into
                      dynamic, versatile designs. Explore how these inspired patterns bring a fresh, industrial edge to
                      your spaces, blending functionality with modern aesthetics. </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2022/03/2200671_Signature_Outbound-LR.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <OutboundCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <OutboundCollectionFeaturedStylesMobile/>
          </section>

          {/* End Outbound Collection Section */}

          <div className="spark-video" style={{backgroundColor: "white"}}>
              <h3>Explore Featured Running Line Collections</h3>
          </div>

          {/* Begin Broadloom Running Line Section */}

          <div className="hospitality-header">
              <div className="hospitality-header-content"
                   style={{backgroundImage: `url("/wp-content/uploads/2024/10/Journey_Crossing_HC044_1280x500.jpg")`}}>
              </div>
          </div>

          <div className="spark-video" style={{backgroundColor: "white", marginBottom: "-15px"}}>
              <p><br></br></p>
              <h3>Broadloom Running Line</h3>
              <p style={{margin: "0% 18% 0% 18%", textAlign: "center"}}>Signature Flooring’s collection of running line
                  encompasses a wide array of products, including broadloom, carpet tile, rugs, entryway systems and
                  accessories. Each product in our extensive portfolio is thoughtfully designed to elevate any space
                  with global appeal and timeless charm. The Oxford II and Boucle II Collection along with the Journey
                  Collection are broadloom running line collections featured here.</p>
              <p><br></br></p>


              {/*
		
		<div className="buttons-wrapper">
		
            <div className="design-button">
            <a
              href="/wp-content/uploads/2021/11/2200237_Signature_Journey-web.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Journey Brochure
              </button>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<a
              href="/wp-content/uploads/2022/08/SIG229-Oxford-Boucle-brochure_final-compressed.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Oxford II and Boucle II Brochure
              </button>
            </a>
            </div>

		</div>
		
		*/}

              <p><br></br></p>

          </div>

          {/* End Broadloom Running Line Section */}

          {/* Begin Oxford & Boucle Section */}

          <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2024/10/BoucleII_8510_1280x500.jpg")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-youtube" style={{backgroundColor: "white"}}>
                  <YouTube videoId="8MNZ-FCBw7k" opts={opts}/>
              </div>


              <div className="smb-graphic2">
                  <a href="/custom_collections/oxford-boucle-collection/" target="_new">
                      <img src="/wp-content/uploads/2024/10/OxfordII_452x380_web.jpg" alt="Oxford II & Boucle II"
                           width="350px" height="310px"/>
                  </a>
              </div>


              <div className="smb-text2">
                  <p className="bold-text">Oxford ll and Boucle ll Collection</p>
                  <p>Reminiscent of classically refined fabrics that have stood the test of time, Oxford II and Boucle
                      II, along with other optional patterns, bring timeless aesthetic influences to design projects.
                      The defined, clean lines and the rich, organic textures move seamlessly from fashion to interiors.
                      Additional pattern options are available in all colorways of Oxford II and Boucle II and are
                      considered running line.</p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2022/08/SIG229-Oxford-Boucle-brochure_final-compressed.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                          <a
                              href="/custom_collections/oxford-boucle/"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  View Entire Collection
                              </button>
                          </a>
                      </div>
                  </div>
              </div>
          </div>

          {/* End Oxford & Boucle Section */}

          {/* Begin Journey Section */}

          <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2024/10/Journey_OdysseyB7016_1280x500.jpg")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-youtube" style={{backgroundColor: "white"}}>
                  <YouTube videoId="4rJzIU7hnIo" opts={opts}/>
              </div>


              <div className="smb-graphic2">
                  <a href="/productinfo/journey/" target="_new">
                      <img src="/wp-content/uploads/2024/10/Journey_Passage_452x380_web.jpg" alt="Journey" width="350px"
                           height="310px"/>
                  </a>
              </div>


              <div className="smb-text2">
                  <p className="bold-text">Journey Collection</p>
                  <p>Journey brings a world of rich texture and sophistication allowing designers to push their
                      creativity without boundaries. With a global composition, this collection blends seamlessly into
                      any design portfolio and effortlessly into many genres of style. Additional pattern options are
                      available in all colorways of Journey and are considered running line.</p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2021/11/2200237_Signature_Journey-web.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                          <a
                              href="/productinfo/journey/"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  View Entire Collection
                              </button>
                          </a>
                      </div>
                  </div>
              </div>
          </div>

          {/* End Journey Section */}

          <div className="spark-video" style={{backgroundColor: "white"}}>
              <h3>Discover SignatureNOW</h3>
          </div>

          {/* Begin Signature Now Section */}

          <div className="hospitality-header"
               style={{backgroundImage: `url("/wp-content/uploads/2024/10/ModernStyle_Neo-4501_1280x450.jpg")`}}>
              <a href="/now/" target="_new">
                  <img src="/wp-content/uploads/2024/10/ModernStyle_Neo-4501_1280x450.jpg" alt="SignatureNOW"/>
              </a>
          </div>

          <div className="spark-video" style={{backgroundColor: "white", marginBottom: "-15px"}}>
              <p style={{margin: "0% 18% 0% 18%", textAlign: "center"}}>Featuring a broadloom carpet program along with
                  LVT flooring delivering in four weeks or less, solving your carpet needs with incredible style,
                  service and value. SignatureNOW by Signature Flooring.</p>
              <p><br></br></p>
          </div>

          {/* End  Signature Now Section */}

          <div className="spark-video" style={{backgroundColor: "white"}}>
              <h3>Explore Featured LVT Collections</h3>
          </div>

          {/* Begin Urban Escapes Section */}

          <div className="hospitality-outbound-collection-hero"
               style={{backgroundImage: `url("/wp-content/uploads/2023/10/Urban-Escapes-1280x500-HeroFinal_resized.jpg")`}}>
              <div className="hospitality-outbound-collection-hero-content">
              </div>
          </div>

          <div className="hospitality-marketing-block">
              <div className="smb-graphic">
                  <a href="/productinfo/urban-escapes-spc/" target="_new">
                      <img src="/wp-content/uploads/2023/10/Urban-Escapes-380x452-Final_resized.jpg" alt="Urban Escapes"
                           height="300px"/>
                  </a>
              </div>
              <div className="smb-text" style={{paddingTop: "24px"}}>
                  <p className="bold-text">Urban Escapes</p>
                  <p>Urban Escapes Biophilic design principles, allows for a connection with nature, creating a sense of
                      warmth, beauty and well-being; and an enjoyment to the spaces we work, live and play. Available in
                      two constructions; loose lay with non-skid backing or Stone Polymer Core (SPC). </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2023/06/SIG354-UrbanEscape-broch-udpates_v4-1-FINAL.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          {/* End Urban Escapes Section */}

          {/* Begin Custom Design / Spark / Sustinable / Installations Section */}

          <div className="sl-hospitality-segment-design-types">
              <div className="design-rows  ">

                  <div className="design-row">
                      <div className="design-header">Custom Design Services</div>
                      <p><br></br></p>
                      <div className="design-content-hero">
                          <img src="/wp-content/uploads/2024/10/Lamina-Custom-Design-Services.jpg"
                               alt="Custom Design Services"/>
                      </div>
                      <div className="design-content">
                          If you can dream it, our seasoned experts can create it: see
                          how quick and easy it is to get fully custom carpet that
                          achieves your vision.
                      </div>
                      <div className="design-button" style={{paddingTop: '35px', margin: '0 auto'}}>
                          <a href="/customcollections">
                              <button className="design-row-button">
                                  View Collections
                              </button>
                          </a>
                      </div>
                  </div>

                  <div className="design-row">
                      <div className="design-header">Signature Spark</div>
                      <p><br></br></p>
                      <div className="design-content-hero">
                          <img src="/wp-content/uploads/2023/10/SignatureSpark-273x273-1_resized.jpg"
                               alt="Signature Spark"/>
                      </div>
                      <div className="design-content">
                          Time to play: use our intuitive design tool to recolor any
                          style, view styles in different room scenes and download your
                          customized flooring creations.{" "}
                      </div>
                      <div className="design-button" style={{paddingTop: '35px', margin: '0 auto'}}>
                          <a href="/signature-spark">
                              <button className="design-row-button-large">
                                  Explore Signature Spark
                              </button>
                          </a>
                      </div>
                  </div>

                  <div className="design-row">
                      <div className="design-header">Sustainable Solutions</div>
                      <p><br></br></p>
                      <div className="design-content-hero">
                          <img src="/wp-content/uploads/2023/10/FarmToFloor-273x273_resized.jpg"
                               alt="Sustainable Solutions"/>
                      </div>

                      <div className="design-content">
                          Signature’s BioCel™ backing replaces a percentage of the petroleum-based polymers with
                          renewable, bio-based USA-grown material.
                      </div>
                      <div className="design-button" style={{paddingTop: '35px', margin: '0 auto'}}>
                          <a href="/sustainability">
                              <button className="design-row-button">Find Out More</button>
                          </a>
                      </div>
                  </div>

                  <div className="design-row">
                      <div className="design-header">Project Installations</div>
                      <p><br></br></p>
                      <div className="design-content-hero">
                          <img src="/wp-content/uploads/2024/10/Project_Installations_web.jpg"
                               alt="Project Installations"/>
                      </div>
                      <div className="design-content">
                          Signature is driven to achieve unique flooring solutions that expand each client's vision. See
                          how we make Signature Spaces a reality.
                      </div>
                      <div className="design-button" style={{paddingTop: '35px', margin: '0 auto'}}>
                          <a href="/installations">
                              <button className="design-row-button-large">
                                  View Projects
                              </button>
                          </a>
                      </div>
                  </div>

              </div>
          </div>

          {/* End Custom Design / Spark / Sustinable / Installations Section */}

      </div>
  );
};

export default SegmentsHospitality;