import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Popup2 from "reactjs-popup";
import "./styles/header_custom.css";

import SampleCart from "../CartComponents/Cart";

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.orderSample = this.orderSample.bind(this);
    this.closemodal = this.closemodal.bind(this);
    this.showSearch = this.showSearch.bind(this);

    this.state = {
      isOpen: false,
      SignatureFlooringSettings: window.SignatureFlooringSettings,
      dropdownOpen: false,
      shoppingcart: JSON.parse(localStorage.getItem("shoppingcart")),
      cartitemcount: JSON.parse(localStorage.getItem("cartitemcount")),
      open: false,
      openSearch: false,
      redirect: false,
      fields: {
        frmSearchInput: "",
      },
    };

    this.submitSearch = this.submitSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/s/search/foo" />;
    }
  };

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  toggle() {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  closemodal() {
    this.setState({ open: false });
  }

  orderSample() {
    this.setState({ open: true });
  }

  showSearch() {
    this.setState({ openSearch: true });
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  submitSearch(e) {
    e.preventDefault();
    console.log("search", this.state.fields.frmSearchInput);
    window.location.assign(
      "/s/search/" + this.state.fields.frmSearchInput + "/sss"
    );
  }

  render() {
    const { SignatureFlooringSettings } = this.state;
    if (this.state.shoppingcart) {
      console.log("shoppingcart: ", this.state.shoppingcart.length);
    }

    return (
      <div>
        <div className="wsmobileheader clearfix ">
          <a id="wsnavtoggle" className="wsanimated-arrow" href="##">
            <span />
          </a>
          <span className="smllogo">
            <a href={SignatureFlooringSettings.path}>
              <img
                src="/wp-content/uploads/2018/11/company_logo-new2.png"
                alt=""
              />
            </a>
          </span>
        </div>

        <div className="wsmainfull clearfix">
          <div className="wsmainwp clearfix">
            <div className="desktoplogo">
              <a href={SignatureFlooringSettings.path}>
                <img
                  src="/wp-content/uploads/2018/11/company_logo-new2.png"
                  alt=""
                />
              </a>
            </div>
            <nav className="wsmenu clearfix">
              <ul className="wsmenu-list">
                <li aria-haspopup="true" className="rightmenu">
                  <form className="topmenusearch" onSubmit={this.submitSearch}>
                    <input
                      ref="frmSearchInput"
                      type="text"
                      name="frmSearchInput"
                      alue={this.state.fields.frmSearchInput}
                      onChange={this.handleChange}
                    />
                    <button className="btnstyle" type="submit">
                      <i
                        className="searchicon fa fa-search"
                        aria-hidden="true"
                      />
                    </button>
                  </form>
                </li>
                <li aria-haspopup="true">
                  <a href="##">Carpet </a>
                  <div className="wsmegamenu clearfix ">
                    <div className="container">
                      <div className="row">					  
  
					    
						{/*
						
						<div className="carpet-submenu-large col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "carpet/broadloom"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2018/12/broadloom-menu.jpg"
                                alt="Broadloom"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Broadloom</p>
                          </a>
                        </div>
						
						<div className="carpet-submenu-large col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "carpet/Cove Base"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2024/01/CoveBase_DropDown_Carpet_SIG_500x300px.jpg"
		                        alt="Cove Base"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Cove Base</p>
                          </a>
                        </div>
						
						*/}
						
                        <div className="carpet-submenu-large col-md-8 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "carpet/broadloom"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2018/12/broadloom-menu.jpg"
                                alt="Broadloom"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Broadloom</p>
                          </a>
                        </div>
						
						<div className="carpet-submenu-large col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "carpet/Cove Base"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2024/01/CoveBase_DropDown_Carpet_SIG_500x300px.jpg"
		                        alt="Cove Base"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Cove Base</p>
                          </a>
                        </div>
						
                        <div className="carpet-submenu-large col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "carpet/Carpet Tile"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2019/03/SIG_ElementsExposed_7401_Thermal_BrickAshlar_menu.jpg"
                                alt="Carpet Tile"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Carpet Tile</p>
                          </a>
                        </div>
						
                        <div className="carpet-submenu-large col-md-8 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path + "carpet/rugs"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2018/12/rugs-menu.jpg"
                                alt="Rugs"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Rugs</p>
                          </a>
                        </div>
						
                        <div className="carpet-submenu-large col-md-8 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "customcollections"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2018/12/customcollection-menu.jpg"
                                alt="Custom Collections"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Custom Collections</p>
                          </a>
                        </div>
						
                        <div className="carpet-submenu-large col-md-8 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "carpet/Entryway System"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2020/07/entryway-menu.jpg"
                                alt="Entryway System"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Entryway System</p>
                          </a>
                        </div>
						
                        <div className="carpet-submenu-large col-md-8 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "now"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2020/07/signow-menu-box.png"
                                alt="Signature Now"
                              />{" "}
                            </div>
                          </a>
                        </div>

                        <div className="carpet-submenu-large col-md-8 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "stacy-garcia"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2025/03/sg-navigiation-logo.png"
                                alt="Stacy Garcia"
                              />{" "}
                            </div>
                          </a>
                        </div>					
						
                      </div>
                    </div>
                  </div>
                </li>
                <li aria-haspopup="true">
                  <a href="##">Hard Surface </a>
                  <div className="wsmegamenu clearfix ">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-2 col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "hardsurface/LVT/"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2018/12/psclick-menu.jpg"
                                alt="LVT"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">LVT</p>
                          </a>
                        </div>
												<div className="col-lg-2 col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path + "hardsurface/SPC"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2022/09/menuspc.jpg"
                                alt="SPC"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">SPC</p>
                          </a>
                        </div>
                        <div className="col-lg-2 col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path + "hardsurface/WPC"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2019/03/menuwpc.jpg"
                                alt="WPC"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">WPC</p>
                          </a>
                        </div>
                        <div className="col-lg-2 col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "hardsurface/Loose Lay"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2019/05/real-appeal-menu.jpg"
                                alt="WPC"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Loose Lay</p>
                          </a>
                        </div>
						
						            <div className="col-lg-2 col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "hardsurface/Rubber Wall Base"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2023/08/rubberwallbase-menu.jpg"
                                alt="Rubber Wall Base"
                              />{" "}
                            </div>
                            <p className="wsmwnutxt">Rubber Wall Base</p>
                          </a>
                        </div>

                        <div className="col-lg-2 col-md-12 col-xs-12">
                          <a
                            href={
                              SignatureFlooringSettings.path +
                              "stacy-garcia"
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div className="fluid-width-video-wrapper">
                              <img
                                src="/wp-content/uploads/2025/03/sg-navigiation-logo.png"
                                alt="Stacy Garcia Studios"
                              />{" "}
                            </div>
                          </a>
                        </div>
						
                      </div>
                    </div>
                  </div>
                </li>
                <li aria-haspopup="true">
                  <a href="##">Segments </a>
                  <ul className="sub-menu">
                    <li aria-haspopup="true">
                      <a href="/segments/hospitality">Hospitality</a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/segments/seniorliving">Senior Living</a>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup="true">
                  <a href="##"> Design </a>
                  <ul className="sub-menu">
                    <li aria-haspopup="true">
                      <a href="/signature-spark">Signature Spark</a>
                    </li>
                    <li aria-haspopup="true">
                      <a
                        href={
                          SignatureFlooringSettings.path + "customcollections"
                        }
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Custom Collections
                      </a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/customdesign">Custom Design Services</a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/installations">Project Installations</a>
                    </li>
                  </ul>
                </li>
				<li aria-haspopup="true">
                  <a href="/sustainability"> Sustainability </a>
                </li>
                <li aria-haspopup="true">
                  <a href="##">Branded Programs</a>
                  <ul className="sub-menu">
                    <li aria-haspopup="true">
                      <a href="/branded-hotel-programs">Hotel Programs</a>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup="true">
                  <a href="/resources"> Resources </a>
                  <ul className="sub-menu">
                    <li aria-haspopup="true">
                      <a href="/r/resources/specs">Specifications</a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/r/resources/installation">Installation</a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/r/resources/sundries">Sundries</a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/r/resources/maintenance-resources">
                        Care & Maintenance
                      </a>
                    </li>
                    <li aria-haspopup="true">
                      <a href="/r/resources/warranties">Warranties</a>
                    </li>
                    <li aria-haspopup="true"><a href="/r/resources/antimicrobial">Antimicrobial</a></li>
                    <li aria-haspopup="true"><a href="/r/resources/cads">CADS</a></li>
                  </ul>
                </li>
                <li aria-haspopup="true">
                  <a href="/salesreps">Find a Rep</a>
                </li>
                <li aria-haspopup="true" id="desktop-cart-button">
                  <a href="##" onClick={this.orderSample}> 
                    { this.state.cartitemcount !== 0 &&
                      <span style={{position: 'absolute', right: '13px', top: '10px', zIndex: '100'}}>
                        {/* this.state.cartitemcount */}  
                      </span>
                    }
                    <img
                      src="/wp-content/uploads/2020/09/cart-empty-no-line.png"
                      alt="shopping-cart-icon"
                      style={{position: 'absolute', top: '30px', right: '5px'}}
                    />
                  </a>
                  <Popup2
                    open={this.state.open}
                    onClose={this.closemodal}
                    modal
                    lockScroll={true}
                    position="top center"
                  >
                    {(close) => <SampleCart close={close} />}
                  </Popup2>
                </li>

                <li aria-haspopup="true" id="mobile-cart-button">
                  <a href="/mobile/cart">
                    { this.state.cartitemcount !== 0 &&
                      <span style={{position: 'absolute', top: '-2px', right: '203px', zIndex: '100'}}>
                        {/* this.state.cartitemcount */}  
                      </span>
                    }
                    <img
                      src="/wp-content/uploads/2020/09/cart-empty-no-line.png"
                      alt="shopping-cart-icon"
                      style={{position: 'absolute', top: '4px'}}
                    />
                    </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
