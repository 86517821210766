import React from "react";
import { render } from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Components/GlobalComponents/Header";
import Footer from "./Components/GlobalComponents/Footer";
import FooterMobile from "./Components/GlobalComponents/FooterMobile";
import Posts from "./posts";
import Post from "./post";
import NotFound from "./not-found";
import Home from "./Pages/Home";
import ProductCategories from "./Pages/ProductCategories";
import Product from "./Pages/Product";
import SalesReps from "./Pages/SalesReps";
import Resources from "./Pages/Resources";
import SegmentsHospitality from "./Pages/SegmentsHospitality";
import SegmentsSeniorLiving from "./Pages/SegmentsSeniorLiving";
//import DesignStudio from './Pages/DesignStudio'
import Spark from "./Pages/Spark";
import BDNY2021 from "./Pages/BDNY2021";
import CustomCollections from "./Pages/CustomCollections";
import CustomCollection from "./Pages/CustomCollection";
import AboutPage from "./Pages/AboutPage";
import TradeShows from "./Pages/TradeShows";
import CustomDesign from "./Pages/CustomDesign";
import MediaPage from "./Pages/Media";
import Sustainability from "./Pages/Sustainability";
import MobileCart from "./Pages/MobileCart";
import Search from "./Pages/Search";
import CovidPage from "./Pages/CovidNotice";
import BrandedHotelPrograms from "./Pages/BrandedHotelPrograms";
import Installations from "./Pages/installations";
import InstallationProject from "./Pages/InstallationPage";
import SignatureNow from "./Pages/SignatureNow";
import StacyGarcia from "./Pages/StacyGarcia";

//import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";

// Load the CSS file
require("./dist/style.css");
require("./dist/divi-styles.css");
require("./mainstyles.css");
const SignatureFlooringSettings = window.SignatureFlooringSettings;

ReactGA.initialize(process.env.REACT_APP_GOOGLE_CODE);
ReactGA.pageview(window.location.pathname + window.location.search);



const App = () => (
  <div>
    <Header />
    <div id="content">
      <Switch>
        <Route exact path={SignatureFlooringSettings.path} component={Home} />
        <Route
          exact
          path={SignatureFlooringSettings.path + "aboutus"}
          component={AboutPage}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "covid-19"}
          component={CovidPage}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "branded-hotel-programs"}
          component={BrandedHotelPrograms}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "mobile/cart"}
          component={MobileCart}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "salesreps"}
          component={SalesReps}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "resources"}
          component={Resources}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "resources/:resourcetype"}
          component={Resources}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "r/resources/:resourcetype"}
          component={Resources}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "resource_docs/:resourcetype"}
          component={Resources}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "s/search/:searchcriteria/sss"}
          component={Search}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "s/search"}
          component={Search}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "media"}
          component={MediaPage}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "media/:mediatype"}
          component={MediaPage}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "customdesign"}
          component={CustomDesign}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "sustainability"}
          component={Sustainability}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "sustainability-page"}
          component={Sustainability}
        />
        {/*<Route
          exact
          path={SignatureFlooringSettings.path + "sustainability-page"}
          component={Sustainability}
        />*/}
        <Route
          exact
          path={SignatureFlooringSettings.path + "tradeshows"}
          component={TradeShows}
        />
       <Route
          exact
          path={SignatureFlooringSettings.path + "installations"}
          component={Installations}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "installations/:install"}
          component={InstallationProject}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "customcollections"}
          component={CustomCollections}
        />
        <Route
          exact
          path={
            SignatureFlooringSettings.path + "customcollections/:collection"
          }
          component={CustomCollection}
        />
        <Route
          exact
          path={
            SignatureFlooringSettings.path + "custom_collections/:collection"
          }
          component={CustomCollection}
        />
        <Route
          exact
          path={
            SignatureFlooringSettings.path + "now"
          }
          component={SignatureNow}
        />
        <Route
          exact
          path={
            SignatureFlooringSettings.path + "signature-now"
          }
          component={SignatureNow}
        />
        <Route
          exact
          path={
            SignatureFlooringSettings.path + "stacy-garcia"
          }
          component={StacyGarcia}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "segments/seniorliving"}
          component={SegmentsSeniorLiving}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "segments/hospitality"}
          component={SegmentsHospitality}
        />
        {/*<Route exact path={SignatureFlooringSettings.path + "designstudio"} component={DesignStudio} />*/}
        <Route
          exact
          path={SignatureFlooringSettings.path + "signature-spark"}
          component={Spark}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "bdny"}
          component={BDNY2021}
        />
        <Route
          exact
          path={
            SignatureFlooringSettings.path +
            "productinfo/:producttype/:categoryname/:productname/a"
          }
          component={Product}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "productinfo/:productname"}
          component={Product}
        />

        <Route
          exact
          path={SignatureFlooringSettings.path + ":producttype/:categoryname"}
          component={ProductCategories}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + ":producttype/:categoryname/"}
          component={ProductCategories}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "p/:producttype/:categoryname"}
          component={ProductCategories}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "hardsurface/LVT"}
          component={ProductCategories}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "posts"}
          component={Posts}
        />
        <Route
          exact
          path={SignatureFlooringSettings.path + "posts/:slug"}
          component={Post}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
    <div className="footer-desktop">
      <Footer />
    </div>
    <div className="footer-mobile">
      <FooterMobile />
    </div>
  </div>
);

// Routes
const routes = (
  <Router>
    <Route path="/" component={App} />
  </Router>
);

render(routes, document.getElementById("page"));

//registerServiceWorker();
unregister();
