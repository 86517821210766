import React from 'react';
import ProgramDetails from './ProgramDetails';

const ProgramDetailsMobile = (props) => {
  return (
    <>
      <div>
        <img src={props.program.acf.program_main_image.url} height='352' width='480' alt="Wyndham Dining Room" style={{width: '100%', height: '100%'}} />
      </div>
      <div style={{marginTop: '20px'}}>
        <img src={props.program.acf.program_logo_image.url} width="400px" alt="logo" style={{width: '100%', height: '100%'}} />
      </div>
	  
	  {props.program.acf.program_display_format == 'format1' &&
		<div className="w3-center" style={{marginTop: '20px'}}>
			<a href={props.program.acf.program_pdf.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }

	  {props.program.acf.program_display_format == 'format2' && props.program.acf.program_logo_1.url &&
		<div className="w3-center" style={{marginTop: '20px', marginBottom: '20px'}}>
			<img src={props.program.acf.program_logo_1.url} width="125px" alt="logo" />
			<br /><br />
			<a href={props.program.acf.program_brochure_1.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }
	  
	  {props.program.acf.program_display_format == 'format2' && props.program.acf.program_logo_2.url &&
		<div className="w3-center" style={{marginTop: '20px', marginBottom: '20px'}}>
			<img src={props.program.acf.program_logo_2.url} width="125px" alt="logo" />
			<br /><br />
			<a href={props.program.acf.program_brochure_2.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }
	  
	  {props.program.acf.program_display_format == 'format2' && props.program.acf.program_logo_3.url &&
		<div className="w3-center" style={{marginTop: '20px', marginBottom: '20px'}}>
			<img src={props.program.acf.program_logo_3.url} width="125px" alt="logo" />
			<br /><br />
			<a href={props.program.acf.program_brochure_3.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }
	  
	  {props.program.acf.program_display_format == 'format2' && props.program.acf.program_logo_4.url &&
		<div className="w3-center" style={{marginTop: '20px', marginBottom: '20px'}}>
			<img src={props.program.acf.program_logo_4.url} width="125px" alt="logo" />
			<br /><br />
			<a href={props.program.acf.program_brochure_4.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }
	  
	  {props.program.acf.program_display_format == 'format2' && props.program.acf.program_logo_5.url &&
		<div className="w3-center" style={{marginTop: '20px', marginBottom: '20px'}}>
			<img src={props.program.acf.program_logo_5.url} width="125px" alt="logo" />
			<br /><br />
			<a href={props.program.acf.program_brochure_5.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }
	  
	  {props.program.acf.program_display_format == 'format2' && props.program.acf.program_logo_6.url &&
		<div className="w3-center" style={{marginTop: '20px', marginBottom: '20px'}}>
			<img src={props.program.acf.program_logo_6.url} width="125px" alt="logo" />
			<br /><br />
			<a href={props.program.acf.program_brochure_6.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
		</div>
	  }
	  
    </>
  )
}

export default ProgramDetailsMobile;