import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";

import RendezvousOasisCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousOasisCollectionFeaturedStyles";
import "./styles/segmentshospitality.css";
import RendezvousOasisCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousOasisCollectionFeaturedStylesMobile";
import RendezvousLandscapesCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousLandscapesCollectionFeaturedStyles";
import RendezvousLandscapesCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousLandscapesCollectionFeaturedStylesMobile";
import RendezvousMonarchCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousMonarchCollectionFeaturedStyles";
import RendezvousMonarchCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousMonarchCollectionFeaturedStylesMobile";
import RendezvousVistaCollectionFeaturedStyles from "../Components/HospitalityComponents/RendezvousVistaCollectionFeaturedStyles";
import RendezvousVistaCollectionFeaturedStylesMobile from "../Components/HospitalityComponents/RendezvousVistaCollectionFeaturedStylesMobile";



const StacyGarcia = () => {
	
  {/*
  let opts = {
    width: "100%",
    height: "310"
  };
  */}
  
  let opts = {
    width: "85%",
    height: "310"
  };
  
  
  return (
      <div className="hospitality-wrapper">

        {/* <div className="hospitality-header">
            <div className="hospitality-header-content"></div>
        </div>

        <div className="spark-video" style={{backgroundColor: "white", marginBottom: "-15px"}}>
            <p><br></br></p>
            <h3>An Exceptional Experience</h3>
            <p style={{margin: "0% 20% 0% 20%", textAlign: "center"}}>From extraordinary custom carpets to
                  sophisticated running-line soft surface and hard surface flooring, Signature Flooring offers an
                  unparalleled level of design, quality and service to help you create an exceptional experience for
                  guests. Discover our exciting range of flooring and custom design capabilities, and start bringing
                  your Signature space to life.</p>
            <p><br></br></p>
        </div> */}

        <div className="spark-video" style={{backgroundColor: "white", paddingTop: '20px'}}>
            <h3>Explore Stacy Garcia Collections</h3>
        </div>

       {/* Begin Rendezvous Collection Section */}

       <div className="hospitality-rendezvous-collection-hero">
            <a href="/custom_collections/rendezvous-oasis-collection/">
                <div className="hospitality-rendezvous-collection-hero-content" style={{paddingBottom: "20px!important"}}></div>
            </a>
        </div>

        <div className="hospitality-generic-video-hero" style={{ marginTop: '10px'}}>
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Oasis-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

            <div className="hospitality-marketing-block">
                <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>


                <div className="smb-graphic2">
                    <a href="/custom_collections/rendezvous-oasis-collection/">
                        <img src="/wp-content/uploads/2024/12/Rendezvous-Oasis-RS_SG009_500x500.jpg" alt="Journey" width="350px" height="310px"/>
                    </a>
                </div>

                <div className="smb-text2">
                    <p className="bold-text">Rendezvous Oasis</p>
                    <p>Rendezvous Oasis Collection captures the lush, vibrant essence of an outdoor sanctuary. Its rich, earthy tones and striking designs create a deep connection to nature.</p>
                    <p>The Rendezvous Oasis Collection introduces a refreshing aesthetic that enriches and rejuvenates guestrooms, public spaces and corridors, inviting a serene ambiance into every space. Rendezvous Oasis designed in collaboration with Stacy Garcia Design Studio.</p>
                    <div className="buttons-wrapper">
                        <div className="design-button">
                            <a href="/wp-content/uploads/2024/10/Signature-Flooring_Oasis_Web.pdf" target="_new" rel="noopener noreferrer">
                                <button className="design-row-button-large">
                                    Download Brochure
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ds-featuredstyles-section">
                <RendezvousOasisCollectionFeaturedStyles />
            </section>

            <section className="ds-featuredstyles-section-mobile">
                <RendezvousLandscapesCollectionFeaturedStylesMobile/>
            </section>


            {/** Rendezvous Landscapes */}
            <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Landscapes-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
          <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>
              <div className="smb-graphic2">
                  <a href="productinfo/rendezvous-landscapes-atmosphere/" target="_new">
                      <img src="/wp-content/uploads/2025/03/StacyGarcia_SG005-Ridge_1507-Stone_RS_500x500.jpg" alt="Rendezvous Landscapes Collection"
                           height="310px" width="350"/>
                  </a>
              </div>
              <div className="smb-text2">
                  <p className="bold-text">Rendezvous Landscapes Collection</p>
                  <p>Rendezvous Landscapes combines luxurious elegance with soothing organic forms and natural textures, crafting a calm and inviting atmosphere for guestroom interiors.</p>
                  <p>Striking the perfect balance between beauty and serenity, it elevates the space with a refined tranquility, enhancing the overall guest experience. Rendezvous Landscapes designed in collaboration with Stacy Garcia Design Studio.</p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2025/03/Landscapes-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <RendezvousLandscapesCollectionFeaturedStyles/>
          </section>

          
          {/** End Rendezvous Landscapes */}

        {/** Rendezvous Monarch */}
        <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Monarch-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
          <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>
              <div className="smb-graphic2">
                  <a href="productinfo/rendezvous-monarch-flight/" target="_new">
                      <img src="/wp-content/uploads/2025/03/SG023T-Flow_1803-Canyon_RS_500x500.jpg" alt="Rendezvous Monarch Collection"
                           height="310px" width="350px"/>
                  </a>
              </div>
              <div className="smb-text2">
                  <p className="bold-text">Rendezvous Monarch Collection</p>
                  <p>Inspired by the delicate symmetry and vibrant colors of butterfly wings, Rendezvous Monarch captures the essence of elegance and fluidity, making it a stunning addition to any design.</p>
                  <p>This collection offers a harmonious blend of intricate patterns and rich textures, creating a visual tapestry that brings the enchanting allure of the natural world into the heart of hospitality environments. Rendezvous Monarch designed in collaboration with Stacy Garcia Studio.
                  </p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2025/03/Monarch-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <RendezvousMonarchCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <RendezvousMonarchCollectionFeaturedStylesMobile/>
          </section>
          
          {/** End Rendezvous Monarch */}


          {/** Rendezvous Vista */}
        <div className="hospitality-generic-video-hero">
              <div className="hospitality-generic-video-hero-content" style={{
                  height: "500px",
                  backgroundImage: `url("/wp-content/uploads/2025/04/Vista-Hero-Hospitality.png")`
              }}>
              </div>
          </div>

          <div className="hospitality-marketing-block">
                <div className="smb-youtube" style={{backgroundColor: "white"}}>
                    <YouTube videoId="wh8Ar6IVyDE" title="Rendezvous Oasis" thum opts={opts}/>
                </div>
              <div className="smb-graphic2">
                  <a href="productinfo/rendezvous-vista-spc/" target="_new">
                      <img src="/wp-content/uploads/2025/03/SIG558-SPC6130_RS_500x500-1.jpg" alt="Rendezvous Vista Collection"
                            width="350px" height="310px"/>
                  </a>
              </div>
              <div className="smb-text2">
                  <p className="bold-text">Rendezvous Vista Collection</p>
                  <p>Rendezvous Vista draws its design inspiration from the concept of expansive views and serene, unspoiled landscapes, bringing the essence of nature indoors.</p>
                  <p>This collection is a tribute to creating interiors that reflect the grandeur of open vistas, blending organic beauty with refined, modern elegance. Rendezvous Vista designed in collaboration with Stacy Garcia Studio.</p>
                  <div className="buttons-wrapper">
                      <div className="design-button">
                          <a
                              href="/wp-content/uploads/2025/03/Vista-Brochure.pdf"
                              target="_new"
                              rel="noopener noreferrer"
                          >
                              <button className="design-row-button-large">
                                  Download Brochure
                              </button>
                          </a>
                          &nbsp;&nbsp;
                      </div>
                  </div>
              </div>
          </div>

          <section className="ds-featuredstyles-section">
              <RendezvousVistaCollectionFeaturedStyles/>
          </section>

          <section className="ds-featuredstyles-section-mobile">
              <RendezvousVistaCollectionFeaturedStylesMobile/>
          </section>
          
          {/** End Rendezvous Vista */}


          {/* End Rendezvous Collection Section */}


      </div>
  );
};

export default StacyGarcia;