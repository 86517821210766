import React from 'react';

const ProgramDetails = (props) => {
  let style = {
    display: 'flex',
    paddingBottom: '20px',
    padddingTop: '55px'
  };
  if (props.index === 'odd') {
    style = {
      display: 'flex',
      flexDirection: 'row-reverse',
      paddingBottom: '10px',
      padddingTop: '50px'
    };
  }
  

  return (
    <div className="branded-program" key={props.program.id}>
	
		<div className="branded-program-format1-wrapper w3-container style={style}">
			  
			<div className="w3-row" style={{paddingTop: '25px'}}>			

				<div className="w3-col m6 l6 w3-center">
					<img src={props.program.acf.program_logo_image.url} width="400px" alt="logo" />
					{props.program.acf.program_display_format == 'format1' &&
						<a href={props.program.acf.program_pdf.url} target="_new"><button style={{marginTop: '25px', color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
					}
				</div>
				  
				<div className="w3-col m6 l6">
					<img src={props.program.acf.program_main_image.url} height='352' width='480' alt="Main Program Image" />					
				</div>
					
			</div>
				
		</div>
	  
		{/* If new 'format2', render it as requested */}
		{props.program.acf.program_display_format == 'format2' &&

			<div className="w3-container" style={{paddingLeft: '0px', paddingRight: '0px'}}>

				<div className="w3-row" style={{marginTop: '25px'}}>
				
					{/* Only render if there is a logo 1 image */}									
					<div className="w3-col m6 l3 w3-center">
						&nbsp;
						{props.program.acf.program_logo_1.url &&
							<div>
								<img src={props.program.acf.program_logo_1.url} width="125px" alt="logo" />
								<br /><br />
								<a href={props.program.acf.program_brochure_1.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
							</div>
						}
					</div>
					
					{/* Only render if there is a logo 2 image */}
					<div className="w3-col m6 l3 w3-center">
						&nbsp;
						{props.program.acf.program_logo_2.url &&
							<div>
								<img src={props.program.acf.program_logo_2.url} width="125px" alt="logo" />
								<br /><br />
								<a href={props.program.acf.program_brochure_2.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
							</div>
						}
					</div>
				
					{/* Only render if there is a logo 3 image */}
					<div className="w3-col m6 l3 w3-center">
						&nbsp;
						{props.program.acf.program_logo_3.url &&
							<div>
								<img src={props.program.acf.program_logo_3.url} width="125px" alt="logo" />
								<br /><br />
								<a href={props.program.acf.program_brochure_3.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
							</div>
						}
					</div>
					
					{/* Only render if there is a logo 4 image */}
					<div className="w3-col m6 l3 w3-center">
						&nbsp;
						{props.program.acf.program_logo_4.url &&
							<div>
								<img src={props.program.acf.program_logo_4.url} width="125px" alt="logo" />
								<br /><br />
								<a href={props.program.acf.program_brochure_4.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
							</div>
						}
					</div>
				  
				</div>
				
			</div>

		}

		{/* If new 'format2', and there is a need for a second row, render it as requested */}
		{props.program.acf.program_display_format == 'format2' && (props.program.acf.program_logo_4.url || props.program.acf.program_logo_5.url || props.program.acf.program_logo_6.url) &&

			<div className="w3-container" style={{paddingLeft: '0px', paddingRight: '0px'}}>

				<div className="w3-row" style={{marginTop: '25px'}}>				
			
					{/* Only render if there is a logo 5 image */}
					<div className="w3-col m6 l3 w3-center">
						&nbsp;
						{props.program.acf.program_logo_5.url &&
							<div>
								<img src={props.program.acf.program_logo_5.url} width="125px" alt="logo" />
								<br /><br />
								<a href={props.program.acf.program_brochure_5.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
							</div>
						}
					</div>
				
					{/* Only render if there is a logo 6 image */}
					<div className="w3-col m6 l3 w3-center">
						&nbsp;
						{props.program.acf.program_logo_6.url &&
							<div>
								<img src={props.program.acf.program_logo_6.url} width="125px" alt="logo" />
								<br /><br />
								<a href={props.program.acf.program_brochure_6.url} target="_new"><button style={{color: 'grey', border: '1px solid grey', borderRadius: '50px', fontFamily: 'Adelle Sans Sb', background: 'transparent', padding: '15px', opacity: '.6', fontSize: '14px'}}>View Program Details</button></a>
							</div>
						}
					</div>
				  
				</div>
				
			</div>

		}			
	  
    </div>
  )
}

export default ProgramDetails;