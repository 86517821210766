import React, { Component } from "react";
import axios from "axios";
import Masonry from "react-masonry-component";
import "./styles/customcollection.css";
import {Helmet} from "react-helmet";

const masonryOptions = {
  transitionDuration: 0
};

class CustomCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customCollection: [],
      collectionImages: [],
      collectionName: this.props.match.params.collection,
      collectionBrochure: null,
      collectionVideo: null,
      collectionAdditionalText: null,
      collectionPatternCountOverride: null,
      isStacyGarcia: false,
    };
  }

  componentWillMount() {
    axios
      .get(
        "/wp-json/wp/v2/custom-collections/?slug=" + this.state.collectionName
      )
      .then(res => {
        this.setState(
          {
            collectionImages: res.data[0].acf.collection_images,
            customCollection: res.data
          },
          () => {
            this.setState({
              collectionName: this.state.customCollection[0].title.rendered,
              collectionBrochure: this.state.customCollection[0].acf
                .collection_brochure.url,
              collectionVideo: this.state.customCollection[0].acf.collection_video,
              collectionAdditionalText: this.state.customCollection[0].acf.collection_additional_text,
              collectionPatternCountOverride: this.state.customCollection[0].acf.collection_pattern_count_override,
              isStacyGarcia: this.state.customCollection[0].acf.collection_is_stacy_garcia
            });
          }
        );
      })
      .catch(errors => console.log(errors));
  }

  render() {
    const { collectionName, collectionImages, collectionBrochure, collectionVideo, collectionAdditionalText, collectionPatternCountOverride, isStacyGarcia} = this.state;
    const childElements = collectionImages.map(function(element) {
      return (
        <li className="image-element-class" key={element.design_id}>
          {element.design_id !== "" && (
            <a
              href={
                `https://spark.signatureflooring.com/products/open/?ID=` +
                element.design_id
              }
              style={{ color: "#444", textDecoration: "none" }}
              target="_new"
            >
              <img
                src={element.collection_image.url}
                alt={element.style_name}
              />
              <br />
              <span>{element.style_name}</span>
              <br />
              <span>{element.repeat_size}</span>
              <div className="overlay" />
              <div className="button">
                <button> Customize </button>
              </div>
            </a>
          )}
          {element.design_id === "" && (
            <div>
              <img
                src={element.collection_image.url}
                alt={element.style_name}
              />
              <br />
              <span>{element.style_name}</span>
              <br />
              <span>{element.repeat_size}</span>
            </div>
          )}
        </li>
      );
    });

    const childElementsMobile = collectionImages.map(function(element) {
      return (
        <li
          className="image-element-mobile-class"
          key={element.design_id}
          style={{ left: "-1px!important" }}
        >
          <div style={{ textAlign: "left" }}>
            <img
              src={element.collection_image.url}
              alt={element.style_name}
              style={{ textAlign: "center", paddingLeft: "50px" }}
            />
            <br />
            <span style={{ textAlign: "left", paddingLeft: "50px" }}>
              {element.style_name}
            </span>
            <br />
            <span style={{ textAlign: "left", paddingLeft: "50px" }}>
              {element.repeat_size}
            </span>
          </div>
        </li>
      );
    });

    return (
      <div>
         <Helmet>
<meta name="description" content={collectionAdditionalText}/>
            </Helmet>
        <div className="custom-collection-wrapper-desktop">
          <div className="custom-collection-container">
            <div className="custom-collection-header">
              <h1 dangerouslySetInnerHTML={{ __html: collectionName }} />
              {isStacyGarcia === true && 
              <h3><img src="/wp-content/uploads/2025/03/SG-Web-logo.png" style={{ width: '40px', height: '40px' }} /> In Collaboration with Stacy Garcia</h3>
              }
              
              {collectionAdditionalText &&
                <p style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: collectionAdditionalText }}>
                </p>
              }
              
              {collectionPatternCountOverride > 0 && 
               <p style={{ textAlign: "center" }}>              
                {collectionPatternCountOverride} Patterns
                </p>
              }
              {collectionPatternCountOverride <= 0 && 
               <p style={{ textAlign: "center" }}>
               {collectionImages.length} Patterns
               </p>
              }  
            </div>
            <div className="custom-collection-actionbar">
              <p className="custom-collection-actionbar-left">
                <a href="/customcollections">Back to Custom Collections</a>
              </p>
              <p className="custom-collection-actionbar-right">
                <a
                  href={collectionBrochure}
                  target="_new"
                  className="custom-collection-brochure-button"
                >
                  Download Brochure
                </a>&nbsp;
                { collectionVideo &&
                 <a
                  href={collectionVideo}
                  target="_new"
                  className="custom-collection-brochure-button"
                >
                  Collection Video
                </a>
              }
              </p>
              
            </div>
            <div className="custom-collection-body">
              <Masonry
                className={"my-gallery-class"} // default ''
                elementType={"ul"} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              >
                {childElements}
              </Masonry>
            </div>
          </div>
        </div>

        <div className="custom-collection-wrapper-mobile">
          <div className="custom-collection-container-mobile">
            <p
              style={{
                width: "100%",
                textAlign: "left",
                paddingTop: "60px",
                paddingLeft: "20px"
              }}
            >
              <a
                href="/customcollections"
                style={{
                  color: "#A28D5B",
                  fontFamily: "Adelle Sans",
                  fontSize: "12px",
                  textDecoration: "none"
                }}
              >
                Back to Custom Collections
              </a>
            </p>
            <div className="custom-collection-header-mobile">
              <h1 dangerouslySetInnerHTML={{ __html: collectionName }} />
              { collectionAdditionalText &&
                <p style={{ textAlign: "center" }}>
                {collectionAdditionalText}
                </p>
              }
              {collectionPatternCountOverride > 0 && 
               <p style={{ textAlign: "center" }}>              
                {collectionPatternCountOverride} Patterns
                </p>
              }
              {collectionPatternCountOverride <= 0 && 
               <p style={{ textAlign: "center" }}>
               {collectionImages.length} Patterns
               </p>
              }
            </div>
            <div className="custom-collection-actionbar">
              <p style={{ textAlign: "center" }}>
                <a
                  href={collectionBrochure}
                  target="_new"
                  className="custom-collection-brochure-button"
                >
                  Download Brochure
                </a>
              </p>
            </div>
            <div className="custom-collection-body">
              <Masonry
                className={"my-gallery-class"} // default ''
                elementType={"ul"} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              >
                {childElementsMobile}
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomCollection;
