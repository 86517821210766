import React, { useState, useEffect } from 'react';
import CustomEasy from '../Components/PageComponents/CustomEasy'
import "./styles/w3.css";
import './styles/tradeshows.css'

const TradeShows = () => {
  const [tradeShowPageContent, setTradeShowPageContent] = useState({})
  const [hospitality, setHospitality] = useState([])
  const [seniorLiving, setSeniorLiving] = useState([])
  const [general, setGeneral] = useState([])
  const showTypes = ['Hospitality', 'Senior Living', 'General']

  const fetchTradeShowPageContent = async () => {
    const tsApiCall = await fetch('/wp-json/wp/v2/pages?slug=trade-shows')
    const tsContent = await tsApiCall.json();
    setTradeShowPageContent({
      title: tsContent[0].title.rendered,
      content: tsContent[0].content.rendered
    })
  }
  const fetchTradeShows = async (showType) => {
    const showApiCall = await fetch('/wp-json/wp/v2/trade-shows/?filter[meta_query]=category&filter[meta_value]='+showType+'&filter[orderby]=display_order&order=asc')
    const showContent = await showApiCall.json()
    if (showType === 'Hospitality') {
      {/*setHospitality(...hospitality, showContent.sort((a,b) => a.acf.display_order.localeCompare(b.acf.display_order)))*/}
      setHospitality(...hospitality, showContent)
    }
    if (showType === 'Senior Living') {
      setSeniorLiving(...seniorLiving, showContent)
    }
    if (showType === 'General') {
      setGeneral(...general, showContent)
    }
  }

  useEffect(() => {
    fetchTradeShowPageContent()
    showTypes.map((show) => {
      fetchTradeShows(show)
      console.log('hosp', hospitality)
    })
  }, [])

  return (
    <>
    <div className="tradeshows-wrapper">
      <div className="tradeshows-header">
        {/* <div className="tradeshows-header-content">
          <h2>Trade Shows</h2>
        </div> */}
      </div>
      <div className="segemnt-above-fold">
        <h3>{ tradeShowPageContent.title }</h3>
        <div dangerouslySetInnerHTML={{__html: tradeShowPageContent.content}} />
      </div>
	
	<div>
		<img src="/wp-content/uploads/2025/04/Trade-Shows-1280-x-600-px-2.png" style={{ width: "100%"}} />
	</div>
{/* 
    { hospitality.length !== 0 &&
      <section className="tradeshows-hospitality-tradeshows">
        <div className="tradeshow-header">
          <span className="tradeshow-header-text">Trade Shows</span>
        </div>
        <div className="tradeshows-list">
            { hospitality.map((item) => (
              <div className="tradeshow-item" key={item.id}>
                <a href={item.acf.link}><h2>{item.title.rendered}</h2></a>
                <h5>{item.acf.date}</h5>
                <p dangerouslySetInnerHTML={{__html: item.acf.content}}></p>
              </div>
            ))}
          </div>  
      </section>
    }

    { seniorLiving.length !== 0 &&
      <section className="tradeshows-senior-living-tradeshows">
        <div className="tradeshow-header">
          <span className="tradeshow-header-text">Senior Living</span>
        </div>
        <div className="tradeshows-list">
            { seniorLiving.map((item) => (
              <div className="tradeshow-item" key={item.id}>
                <a href={item.acf.link}><h2>{item.title.rendered}</h2></a>
                <h5>{item.acf.date}</h5>
                <p dangerouslySetInnerHTML={{__html: item.acf.content}}></p>
              </div>
            ))}
          </div>  
      </section>
    } 

    { general.length !== 0 &&
      <section className="tradeshows-general-tradeshows">
        <div className="tradeshow-header">
          <span className="tradeshow-header-text-general">General</span>
        </div>
        <div className="tradeshows-list">
            { general.map((item) => (
              <div className="tradeshow-item-general" key={item.id}>
                <a href={item.acf.link}><h2>{item.title.rendered}</h2></a>
                <h5>{item.acf.date}</h5>
                <p dangerouslySetInnerHTML={{__html: item.acf.content}}></p>
              </div>
            ))}
          </div>  
      </section>
    } */}

	{/* Tradeshow Gallery */}
	<section className="tradeshow-gallery-section">
		
		<div className="tradeshow-gallery-wrapper">
	  
			<div className="w3-row tradeshow-gallery">				
	
				<div className="w3-center">
					
					{/* Gallery Desktop Row 1 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/1-1.png" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/12/2-1.png" alt="Booth 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/3-1.png" alt="Group 1" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 2 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/4-1.png" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/12/5-1.png" alt="Booth 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/6-1.png" alt="Group 1" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 3 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/7-1.png" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/12/8-1.png" alt="Booth 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/9-1.png" alt="Group 1" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 4 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/10-1.png" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/12/11-1.png" alt="Booth 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/12/12-1.png" alt="Group 1" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 5 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA24_Booth.jpg" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/04/SIG_EFA24_Booth-2.jpg" alt="Booth 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA24_Group1.jpg" alt="Group 1" className="w3-image"/>
					</div>					


					{/* Gallery Desktop Row 6 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA24_Group2.jpg" alt="Group 2" className="w3-image"/>
					</div>					
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA24_Group3.jpg" alt="Group 3" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA24_Group4.jpg" alt="Group 4" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 7 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA2024_Group5.jpg" alt="Group 5" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_Booth.jpg" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_BackBooth.jpg" alt="Back Booth" className="w3-image"/>
					</div>
					
					{/* Gallery Desktop Row 8 */}			
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_Group1.jpg" alt="Group 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_Group2.jpg" alt="Group 2" className="w3-image"/>
					</div>					
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_Group3.jpg" alt="Group 3" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 9 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY_Group4.jpg" alt="Group 4" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_Group5.jpg" alt="Group 5" className="w3-image"/>
					</div>					
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_Group6.jpg" alt="Group 6" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 10 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_InnovativeBoothAward.jpg" alt="Innovative Booth Award" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY23_StacyGarcia.jpg" alt="Stacy Garcia" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA23_Booth.jpg" alt="Booth" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 11 */}				
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_EFA23_Group2.jpg" alt="Group 2" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/04/SIG_EFA23_Group1.jpg" alt="Group 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/04/SIG_BDNY22_Booth.jpg" alt="Booth" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 12 */}
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY22_BackBooth.jpg" alt="Back Booth" className="w3-image"/>
					</div>					
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY22_Group1.jpg" alt="Group 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY22_Group2.jpg" alt="Group 2" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 13 */}											
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY22_Group3.jpg" alt="Group 3" className="w3-image"/>
					</div>	
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY22_Group4.jpg" alt="Group 4" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY21_BackBooth.jpg" alt="Back Booth" className="w3-image"/>
					</div>

					{/* Gallery Desktop Row 14 */}					
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>			
						<img src="/wp-content/uploads/2024/04/SIG_BDNY21_Booth.jpg" alt="Booth" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY21_Group1.jpg" alt="Group 1" className="w3-image"/>
					</div>
					<div className="w3-col l4 s6 w3-display-container" style={{ marginBottom: "20px" }}>
						<img src="/wp-content/uploads/2024/04/SIG_BDNY21_Group2.jpg" alt="Group 2" className="w3-image"/>
					</div>						

				</div>
			
			</div>
			
		</div>
		
	</section>	

    </div>
    <CustomEasy />
    </>
  )
}

export default TradeShows