import React, { useState, useEffect } from 'react';
import './styles/installations.css';
import { Helmet } from 'react-helmet';
import InstallCard from '../Components/InstallationComponents/InstallCard';

const installations = () => {
  const [pageContent, setPageContent] = useState([]);
  const [installations, setInstallations] = useState({ all: [], filtered: []});
  const [navLinkClass, setNavLinkClass] = useState(['active-link', '', '', '']);
  const SignatureFlooringSettings = window.SignatureFlooringSettings;  
  
  
  const pageName = useState(window.location.pathname);
  const [images, setImages] = useState({});
  
  const fetchData = async () => {
    const allInstallsApiCall = await fetch('/wp-json/wp/v2/installations/?per_page=100');
    const aContent = await allInstallsApiCall.json();
    setInstallations({
      all: aContent,
      filtered: aContent
    });
    
  }


  const headerStyles = {
    width: "100%",
    height: "450px",
    background:
      'linear-gradient(to bottom, transparent 0%, black 175%), url("/wp-content/uploads/2020/08/project-installation-page-header.jpg")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  };

  useEffect(() => {
    fetchData();
    
  }, []);

  const showHide = (num) => {
    if (num === 0) {
      setNavLinkClass(['active-link', '', '', '']);
      setInstallations ({
        all: installations.all,
        filtered: installations.all
      })
    }

    if (num === 1) {
      setNavLinkClass(['', 'active-link', '', '']);
      const hospitalityInstalls = installations.all.filter(item => item.acf.installation_category === 'Hospitality');
      setInstallations({
        all: installations.all,
        filtered: hospitalityInstalls
      })
    }

    if (num === 2) {
      setNavLinkClass(['', '', 'active-link', '' ]);
      const seniorLivingInstalls = installations.all.filter(item => item.acf.installation_category === 'Senior Living');
      setInstallations({
        all: installations.all,
        filtered: seniorLivingInstalls
      })
    }

    if (num === 3) {
      setNavLinkClass(['', '', '', 'active-link' ]);
      const commercialInstalls = installations.all.filter(item => item.acf.installation_category === 'Commercial');
      setInstallations({
        all: installations.all,
        filtered: commercialInstalls
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Project Installations by Signature Flooring</title>
        <meta name="description" content="Signature Flooring project installations" />
        <meta property="og:title" content="Project Installations by Signature Flooring - Floors for Signature Spaces" />
        <link rel="canonical" href={SignatureFlooringSettings.path + "installations/"}/>
      </Helmet>
      <div className="installs-wrapper">
        <div className="installs-header" style={headerStyles}>
          <div className="installs-header-content">
            <h2>Project Installations</h2>
          </div>
        </div>
        <nav className="installations">
          <ul className="nav justify-content-center pt-3"> Filter Projects By: 
            <li><a href="##" onClick={() => showHide(0)} className={navLinkClass[0]}>All Projects</a></li>
            <li><a href="##" onClick={() => showHide(1)} className={navLinkClass[1]}>Hospitality</a></li>
            <li><a href="##" onClick={() => showHide(2)} className={navLinkClass[2]}>Senior Living</a></li>
            <li><a href="##" onClick={() => showHide(3)} className={navLinkClass[3]}>Commercial</a></li>
          </ul>
        </nav>
        
        { installations.filtered.length !== 0 &&
          <>
            <div style={{background: '#fff', width:'100%'}}>
            <div className="container">
            <div className="card-row">
              { installations.filtered.map((item) => (
                <div className="col-md-4 p-3" key={item.id}>
                  <InstallCard data={item} />
                </div>
              ))}
              </div>
              </div>
            </div>
          </>
        }
      </div>  
    </>
  )
}

export default installations;