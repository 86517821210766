import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import CustomEasy from "../Components/PageComponents/CustomEasy";
import ReflectiveEchoesFeaturedStyles from "../Components/SeniorLivingComponents/ReflectiveEchoesFeaturedStyles";
import ReflectiveEchoesFeaturedStylesMobile from "../Components/SeniorLivingComponents/ReflectiveEchoesFeaturedStylesMobile";
import RegionalTapestryFeaturedStyles from "../Components/SeniorLivingComponents/RegionalTapestryFeaturedStyles";
import RegionalTapestryFeaturedStylesMobile from "../Components/SeniorLivingComponents/RegionalTapestryFeaturedStylesMobile";
import AquarellaCollectionFeaturedStyles from "../Components/SeniorLivingComponents/AquarellaCollectionFeaturedStyles";
import AquarellaCollectionFeaturedStylesMobile from "../Components/SeniorLivingComponents/AquarellaCollectionFeaturedStylesMobile";
import CommonThreadsFeaturedStyles from "../Components/SeniorLivingComponents/CommonThreadsFeaturedStyles";
import CommonThreadsFeaturedStylesMobile from "../Components/SeniorLivingComponents/CommonThreadsFeaturedStylesMobile";
import TerraCrudaFeaturedStyles from "../Components/SeniorLivingComponents/TerraCrudaFeaturedStyles";
import TerraCrudaFeaturedStylesMobile from "../Components/SeniorLivingComponents/TerraCrudaFeaturedStylesMobile";
import PolishedCollectionFeaturedStyles from "../Components/SeniorLivingComponents/PolishedCollectionFeaturedStyles";
import PolishedCollectionFeaturedStylesMobile from "../Components/SeniorLivingComponents/PolishedCollectionFeaturedStylesMobile";
import "./styles/segmentsseniorliving.css";

const SegmentsSeniorLiving = () => {
	
  {/*
  let opts = {
    width: "100%",
    height: "310"
  };
  */}
  
  let opts = {
    width: "85%",
    height: "310"
  };
  
  
  return (
    <div className="senior-living-wrapper">

      <div className="senior-living-header">
        <div className="senior-living-header-content">
        </div>
      </div>

      <div className="spark-video" style={{ backgroundColor: "white", marginBottom: "-15px" }}>
        <p><br></br></p>
		<h3>Elegant Style Is Only the Beginning</h3>
        <p style={{ margin: "0% 20% 0% 20%", textAlign: "center" }}>Explore coordinated flooring solutions that are expertly designed for senior living environments. Signature Flooring’s patterns, palettes and performance features are tailored to meet the diverse needs of residents and caregivers, while enhancing the style of every space. Expand your design vision, and start bringing your Signature space to life.</p>
        <p><br></br></p>
        <p><br></br></p>
      </div>
	  
	  {/* Begin Reflective Echoes Section */}
	  
	  <div className="senior-living-reflective-echoes-hero">
        <div className="senior-living-reflective-echoes-hero-content">
        </div>
      </div>
	  
	  <div className="senior-living-marketing-block">
        <div className="smb-graphic">
			<a href="custom_collections/reflective-echoes-collection/" target="_new">
				<img src="/wp-content/uploads/2024/05/Reflective-Echoes_ZD489_600x600.jpg" alt="Reflective Echoes" height="300px" />
			</a>
        </div>
        <div className="smb-text" style={{ paddingTop: "24px" }}>
          <p className="bold-text">Reflective Echoes</p>
          <p>Step into the gentle embrace of nostalgia with the Reflective Echoes Collection, where every design element is thoughtfully curated to evoke emotion and cherished memories. Inspired by the understated elegance of the mid-20th century, our collection of broadloom and carpet tiles delicately intertwines geometric patterns and soft, soothing hues, creating a tranquil environment ideal for senior living spaces. Each step is a tender journey through time, nurturing memory and enveloping residents in a comforting sense of familiarity.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2024/04/SIG-Reflective-Echoes_Brochure.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  
	  <section className="ds-featuredstyles-section">
        <ReflectiveEchoesFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <ReflectiveEchoesFeaturedStylesMobile />
      </section>
	  
	  {/* End Reflective Echoes Section */}
	  
	  {/*
	  <section className="segment-featured-styles-top">
		<div className="segment-featured-header">
		  <p
			className="segment-featured-header-text"
			style={{ textAlign: "center" }}
		  >
			Featured Styles
		  </p>
		</div>
	  </section>
	  */}

	  {/*
      <div className="senior-living-video" style={{ backgroundColor: "white" }}>
        <YouTube videoId="oBrMS5Yx5y4" opts={opts} />
      </div>
	  */}
	  
	  <div className="senior-living-regional-tapestry-hero">
        <div className="senior-living-regional-tapestry-hero-content">
        </div>
      </div>
	  
	  {/*	  
	  <div className="senior-living-marketing-block">
        <div className="smb-graphic">
			<a href="/custom_collections/regional-tapestry-collection/" target="_new">
				<img src="/wp-content/uploads/2023/10/senior-living-regional-tapestry-main.jpg" alt="Regional Tapestry" height="300px" />
			</a>
        </div>
        <div className="smb-text">
          <p className="bold-text">Regional Tapestry</p>
          <p>The REGIONAL TAPESTRY Collection is divided into four “regions” – Coastal, Plains & Lowlands, Southwest and Metropolitan, each capturing inspiration from the natural environments, landscapes, culture and trends that influence each area. Using Biophilic design each region brings its own diverse style and character to a space, while easily blending into each other with similar and subtle color palettes. Together, they create a large collection of tapestry patterns that vary in scale, repeat and contrast that give versatility throughout senior living environments.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2023/04/Regional-Tapestry-Brochure.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  */}
	  
	  <div className="senior-living-marketing-block">
	    <div className="smb-youtube" style={{ backgroundColor: "white" }}>
			<YouTube videoId="oBrMS5Yx5y4" opts={opts} />
        </div>
		
		
        <div className="smb-graphic2">
			<a href="/custom_collections/regional-tapestry-collection/" target="_new">
				<img src="/wp-content/uploads/2023/10/senior-living-regional-tapestry-main.jpg" alt="Regional Tapestry" height="300px" />
			</a>
        </div>
		
		
        <div className="smb-text2">
          <p className="bold-text">Regional Tapestry</p>
          <p>The REGIONAL TAPESTRY Collection is divided into four “regions” – Coastal, Plains & Lowlands, Southwest and Metropolitan, each capturing inspiration from the natural environments, landscapes, culture and trends that influence each area. Using Biophilic design each region brings its own diverse style and character to a space, while easily blending into each other with similar and subtle color palettes.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2023/04/Regional-Tapestry-Brochure.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  
	  <section className="ds-featuredstyles-section">
        <RegionalTapestryFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <RegionalTapestryFeaturedStylesMobile />
      </section>
	  
	  <div className="senior-living-aquarella-collection-hero">
        <div className="senior-living-aquarella-collection-hero-content">
        </div>
      </div>
	  
	  <div className="senior-living-marketing-block">
        <div className="smb-graphic">
			<a href="/custom_collections/aquarella-collection/" target="_new">
				<img src="/wp-content/uploads/2023/10/senior-living-aquarella-collection-main.jpg" alt="Aquarella Collection" height="300px" />
			</a>
        </div>
        <div className="smb-text" style={{ paddingTop: "34px" }}>
          <p className="bold-text">Aquarella Collection</p>
          <p>Inspired by the artistry of watercolor, the Aquarella Collection combines saturated darker tones with brushstrokes of luminosity. The layers of color, texture and patterns allow for visual movement and transition throughout spaces from independent, assisted and memory care. From running line to additional pattern options, the Aquarella Collection is made to offer ease of access to different constructions that give the opportunity to add pops of pattern and color with a variety of scales.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2022/09/SIG446-Aquarella-updates_v1.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  
	  <section className="ds-featuredstyles-section">
        <AquarellaCollectionFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <AquarellaCollectionFeaturedStylesMobile />
      </section>
	  
	  <div className="senior-living-common-threads-hero">
        <div className="senior-living-common-threads-hero-content">
        </div>
      </div>
	  
	  <div className="senior-living-marketing-block">
        <div className="smb-graphic">
			<a href="/productinfo/texture/" target="_new">
				<img src="/wp-content/uploads/2023/10/senior-living-common-threads-main.jpg" alt="Common Threads Collection" height="300px" />
			</a>
        </div>
        <div className="smb-text">
          <p className="bold-text">Common Threads Collection</p>
          <p>Common Threads Collection is designed with residents in mind. This collection gives senior living interiors and multi-family dwellings the warmth and comfort of home with understated texture and a sophisticated, neutral palette. This collection of subtle designs add to a welcoming, home-like aesthetic.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2022/09/SIG295-CT-brochure_update_final.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  
	  {/*	  
	  <section className="ds-featuredstyles-section">
        <CommonThreadsFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <CommonThreadsFeaturedStylesMobile />
      </section>
	  */}
	  
	  <div className="senior-living-terra-cruda-hero">
        <div className="senior-living-terra-cruda-hero-content">
        </div>
      </div>
	  
	  <div className="senior-living-marketing-block">
        <div className="smb-graphic">
			<a href="/custom_collections/terracruda/" target="_new">
				<img src="/wp-content/uploads/2023/10/senior-living-terra-cruda-main.jpg" alt="Terra Cruda" height="300px" />
			</a>
        </div>
        <div className="smb-text" style={{ paddingTop: "24px" }}>
          <p className="bold-text">Terra Cruda</p>
          <p>Terra Cruda describes one of humankind’s earliest architectural materials: raw earth, packed together as walls and floors or shaped into sun-baked bricks.</p>
		  <p>In use for millennia, Terra Cruda not only fosters a connection between the built world and landscape, but also creates a shared sense of culture across generations. The Terra Cruda Collection draws its inspiration from the rugged, natural textures and accumulative layers of these earthen forms.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2019/08/4077_SIG_TerraCruda_Brochure_FIN_02.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  
	  <section className="ds-featuredstyles-section">
        <TerraCrudaFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <TerraCrudaFeaturedStylesMobile />
      </section>
	  
	  <div className="senior-living-polished-collection-hero">
        <div className="senior-living-polished-collection-hero-content">
        </div>
      </div>
	  
	  <div className="senior-living-marketing-block">
        <div className="smb-graphic">
			<a href="/custom_collections/cc-polished/" target="_new">
				<img src="/wp-content/uploads/2023/10/senior-living-polished-collection-main.jpg" alt="Polished" height="300px" />
			</a>
        </div>
        <div className="smb-text">
          <p className="bold-text">Polished</p>
          <p>The Polished Collection explores the dimensional character of natural stone and organic structure. Patterns trace the fluid, intricate veining of marble, fractured facets of ancient rock and layers of fallen leaves on weathered stone.</p>
          <div className="buttons-wrapper">            
            <div className="design-button">
            <a
              href="/wp-content/uploads/2019/03/Polished_Collection_Brochure-1.pdf"
              target="_new"
              rel="noopener noreferrer"
            >
              <button className="design-row-button-large">
                Download Brochure
              </button>
            </a>
            &nbsp;&nbsp;
            </div>
          </div>        
        </div>
      </div>
	  
	  <section className="ds-featuredstyles-section">
        <PolishedCollectionFeaturedStyles />
      </section>
      
      <section className="ds-featuredstyles-section-mobile">
        <PolishedCollectionFeaturedStylesMobile />
      </section>
	  
	 <div className="senior-living-urban-escapes-hero">
		<div className="senior-living-urban-escapes-hero-content">
		</div>
	  </div>
	  
	  <div className="senior-living-marketing-block">
		<div className="smb-graphic">
			<a href="/productinfo/urban-escapes-spc" target="_new">
				<img src="/wp-content/uploads/2023/10/Urban-Escapes-380x452-Final_resized.jpg" alt="Urban Escapes" height="300px" />
			</a>
		</div>
		<div className="smb-text">
		  <p className="bold-text">Urban Escapes</p>
		  <p>Urban Escapes Biophilic design principles, allows for a connection with nature, creating a sense of warmth, beauty and well-being; and an enjoyment to the spaces we work, live and play. Available in two constructions; loose lay with non-skid backing or Stone Polymer Core (SPC).</p>
		  <div className="buttons-wrapper">            
			<div className="design-button">
			<a
			  href="/wp-content/uploads/2023/06/SIG354-UrbanEscape-broch-udpates_v4-1-FINAL.pdf"
			  target="_new"
			  rel="noopener noreferrer"
			>
			  <button className="design-row-button-large">
				Download Brochure
			  </button>
			</a>
			&nbsp;&nbsp;
			</div>
		  </div>        
		</div>
	  </div>
	  
	  {/* <div className="senior-living-local-connections-hero">
		<div className="senior-living-local-connections-hero-content">
		</div>
	  </div> */}
	  
	  {/* <div className="senior-living-marketing-block">
		<div className="smb-graphic">
			<a href="/productinfo/local-connections" target="_new">
				<img src="/wp-content/uploads/2023/10/LocalConnections-380x452_resized.jpg" alt="Local Connections" height="300px" />
			</a>
		</div>
		<div className="smb-text">
		  <p className="bold-text">Local Connections</p>
		  <p>Embracing classics with a hint of modernity, and blending traditional with modern elements. Local Connections, features exquisite oak patterning that are grounded in classic grain textures. Made in the USA providing the most advanced design capabilities, best quality, excellent service, and reduced transportation costs.</p>
		  <div className="buttons-wrapper">            
			<div className="design-button">
			<a
			  href="/wp-content/uploads/2022/09/SignatureFlooring_Local-Connections_Brochure.pdf"
			  target="_new"
			  rel="noopener noreferrer"
			>
			  <button className="design-row-button-large">
				Download Brochure
			  </button>
			</a>
			&nbsp;&nbsp;
			</div>
		  </div>        
		</div>
	  </div> */}

	  <div className="sl-segment-design-types">
		<div className="design-rows  ">
		
		  <div className="design-row">
			<div className="design-header">Custom Design Services</div>
			<div className="design-hr">
			  <hr />
			</div>
			<div className="design-content-hero">
				<img src="/wp-content/uploads/2023/10/CustomDesignServices-273x273_resized.jpg" alt="Custom Design Services" />
			</div>
			<div className="design-content">
			  If you can dream it, our seasoned experts can create it: see
			  how quick and easy it is to get fully custom carpet that
			  achieves your vision.
			</div>
			<div className="design-button" style={{ paddingTop: '35px', margin: '0 auto'}}>
			  <a href="/customcollections">
				<button className="design-row-button">
				  View Collections
				</button>
			  </a>
			</div>
		  </div>

		  <div className="design-row">
			<div className="design-header">Signature Spark</div>
			<div className="design-hr">
			  <hr />
			</div>
			<div className="design-content-hero">
				<img src="/wp-content/uploads/2023/10/SignatureSpark-273x273-1_resized.jpg" alt="Signature Spark" />
			</div>
			<div className="design-content">
			  Time to play: use our intuitive design tool to recolor any
			  style, view styles in different room scenes and download your
			  customized flooring creations.{" "}
			</div>
			<div className="design-button" style={{ paddingTop: '35px', margin: '0 auto'}}>
			  <a href="/signature-spark">
				<button className="design-row-button-large">
				  Explore Signature Spark
				</button>
			  </a>
			</div>
		  </div>

		  <div className="design-row">
			<div className="design-header">Sustainable Solutions</div>
			<div className="design-hr">
			  <hr />
			</div>
			<div className="design-content-hero">
				<img src="/wp-content/uploads/2023/10/FarmToFloor-273x273_resized.jpg" alt="Sustainable Solutions" />
			</div>
			{/*
			<div className="design-content">
			  Signature’s BioCel™ Attached Cushion Backing, replaces a percentage of the petroleum-based polymers with renewable, bio-based material extracted from USA-grown soybeans.
			</div>
			*/}
			<div className="design-content">
			Signature’s BioCel™ backing replaces a percentage of the petroleum-based polymers with renewable, bio-based USA-grown material.
			</div>
			<div className="design-button" style={{ paddingTop: '35px', margin: '0 auto'}}>
			  <a href="/sustainability">
				<button className="design-row-button">Find Out More</button>
			  </a>
			</div>
		  </div>
		  
		  <div className="design-row">
			<div className="design-header">Project Installations</div>
			<div className="design-hr">
			  <hr />
			</div>
			<div className="design-content-hero">
				<img src="/wp-content/uploads/2023/10/Project-Installation-273x273_resized.jpg" alt="Project Installations" />
			</div>
			<div className="design-content">
			Signature is driven to achieve unique flooring solutions that expand each client's vision. See how we make Signature Spaces a reality.
			</div>
			<div className="design-button"  style={{paddingTop: '35px', margin: '0 auto'}}>
			  <a href="/installations">
				<button className="design-row-button-large">
				  View Projects
				</button>
			  </a>
			</div>
		  </div>
		  
		</div>
	  </div>      

    </div>
  );
};

export default SegmentsSeniorLiving;